.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position:relative;
}

footer{
position: absolute;
bottom: -8rem;
}

.container-pdf-bo {
  min-height: calc(100vh - 100px);
}

.pagination-container{
  padding: 25px 25px 0 25px;
}

body{
min-height: 100vh;
}
html {
  height: 100%
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.mb-6{
  margin-bottom:2rem;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* mobile only */
  @media (max-width: 768px) {
    .pagination-container {
      display: flex;
      flex-direction: column;
    }
    .pagination-buttons{
       margin-top:20px
    }
}